import React from 'react';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import Layout from '../components/Layout';

import Video from '../components/Video';

import Card from '../components/Card';
import OsmMap from '../components/OsmMap';


import pic8 from '../assets/images/actors/pommes.jpg';
import pic5 from '../assets/images/actors/ecrin.jpg';
import pic6 from '../assets/images/actors/lpo.png';

const EventPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>L'association</h2>
          <p>Des événements "à part" depuis 1992</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <section>
            <br/>
            <br/>
            <h3 className="major">L'objet</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. </p>
          </section>
          <Video url="https://www.youtube.com/watch?v=jl7xVFLdySA" />
          <section>
            <br/>
            <br/>
            <h3 className="major">Le lieu</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. </p>
          <br/>
          <OsmMap lat="44.5471869" lng="6.4212282" text="Plan d'eau d'Embrun" />
          <br/>
         
          </section>

          
          <section>
            <br/>
            <br/>
            <h3 className="major">Partenaires</h3>
            <br/>

						<div className="box alt">
              <div className="row gtr-uniform">
							
                <div className="col-4"><span className="image fit"><img src={pic8} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic5} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic6} alt="" /></span></div>

							</div>
						</div>

          </section>
          
          <section>
            <br/>
            <br/>
            <h3>L'avis du public</h3>
            <br/>
						<blockquote>"Fringilla nisl. Donec accumsan interdum nisi, quis tincidunt felis sagittis eget tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan faucibus. Vestibulum ante ipsum primis in faucibus lorem ipsum dolor sit amet nullam adipiscing eu felis."</blockquote>
            
          </section>
         
          <br/>
          <br/>
          <h3 className="major">Le bureau</h3>
          <section className="features">
            
            <Card type='team' name="Fabienne" image="0" state="presidente" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet."/>
            <Card type='team' name="Fabienne" image="0" state="presidente" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet."/>
            <Card type='team' name="Fabienne" image="0" state="presidente" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet."/>
            <Card type='team' name="Fabienne" image="0" state="presidente" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet."/>
            <Card type='team' name="Fabienne" image="0" state="presidente" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet."/>
            

          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default EventPage;
